import { tasks, version } from "@/utils/urlConstants";

export default (axios) => ({
  getTasksList(params = {}) {
    return axios.get(`${tasks.base}`, {
      params: params,
    });
  },
  getTasksSelectList(params = {}) {
    return axios.get(`${tasks.base}select/`, {
      params: params,
    });
  },
  getTaskObject(id) {
    return axios.get(`${tasks.base}${id}/`);
  },
  addTask(data) {
    return axios.post(`${tasks.base}`, data);
  },
  updateTask(data, id) {
    return axios.put(`${tasks.base}${id}/`, data);
  },
  addAttachment(data) {
    return axios.post(`${version}attachments/`, data);
  },
  getAttachmentsList(params = {}) {
    return axios.get(`${version}attachments/`, {
      params: params,
    });
  },
  deleteAttachment(id) {
    return axios.delete(`${version}attachments/${id}/`);
  },
  getTaskCheckList(params) {
    return axios.get(`${version}task_checklist/`, { params: params });
  },
  getTaskItemsList(id) {
    return axios.get(`${tasks.base}${id}/task_items/`);
  },
  addTaskItem(data) {
    return axios.post(`${version}task_items/`, data);
  },
  deleteTaskItem(id) {
    return axios.delete(`${version}task_items/${id}/`);
  },
  rescheduleTask(payload) {
    return axios.put(
      `${tasks.base}${payload.id}/reschedule_task/`,
      payload.data
    );
  },
  assignToTrip(payload) {
    return axios.put(
      `${tasks.base}${payload.id}/assign_to_trip/`,
      payload.data
    );
  },
  markTaskAsComplete(payload) {
    return axios.put(
      `${tasks.base}${payload.id}/complete/`,
      payload.data
    );
  },
  failedTask(data,id){
    return axios.put(`${tasks.base}${id}/failed_task/`, data );
  }
});
